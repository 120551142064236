


































































































































































































































































































































import Vue from "vue";

import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Todo from "../../model/todo";
import firebase from "firebase";
import User from "../../model/user";
import Job from "../../model/job";
import clonedeep from "lodash/cloneDeep";
import { Timestamp } from "../../db";
import fuzzysearch from "fuzzysearch-ts";
import { Tag } from "../../model/interfaces";

@Component
export default class TodoItemElement extends Vue {
  @Prop() todo!: Todo;
  @Prop() edit!: boolean;
  @Action addTodo!: (
    todo: Todo
  ) => Promise<firebase.firestore.DocumentReference<Todo>>;
  @Action editTodo!: (
    todo: Todo
  ) => Promise<firebase.firestore.DocumentReference<Todo>>;

  @Getter("getStoreUser") storeUser!: User;
  @Getter("sortedJobs") sortedJobs!: Job[];
  @Getter("users") users!: User[];
  @Getter("getTodoTags") todoTags!: Tag[];
  @Getter("jobs") jobs!: Job[];

  tagmenu: boolean = false;
  projectmenu: boolean = false;
  calendarmenu: boolean = false;
  editing: boolean = false;
  editingTodo: Todo = new Todo();
  projectSearch: string = "";

  get menuFilteredJobs(): Job[] {
    if (this.projectSearch) {
      return this.sortedJobs.filter((job) =>
        fuzzysearch(
          this.projectSearch?.toLowerCase(),
          Job.TitleFormatter(job)?.toLowerCase()
        )
      );
    } else {
      return this.sortedJobs;
    }
  }

  set duedateDatePicker(date: string | undefined) {
    if (date) this.editingTodo.dueDate = Timestamp.fromDate(new Date(date));
  }
  get duedateDatePicker(): string | undefined {
    return this.editingTodo.dueDate?.toDate().toISOString().substring(0, 10);
  }

  get flagtodisplay() {
    let ret: string = "mdi-flag";
    switch (this.editingTodo.priority) {
      case 3:
        ret = "mdi-flag-outline";
    }
    return ret;
  }

  get jobdisplayname(): string {
    let job = this.jobs.find(
      (x) =>
        this.editingTodo.project != undefined &&
        x.id === this.editingTodo.project
    );
    if (job) {
      return Job.TitleFormatter(job);
    } else {
      return "";
    }
  }

  get colortodisplay() {
    let ret: string = "";
    switch (this.editingTodo.priority) {
      case 0:
        ret = "red";
        break;
      case 1:
        ret = "yellow";
        break;
      case 2:
        ret = "blue";
        break;
    }
    return ret;
  }

  @Watch("editing")
  focusText() {
    if (this.editing) {
      const todoDetailsField = this.$refs["todoDetailsField"] as any;
      todoDetailsField.$refs.input.focus();
      this.projectSearch = "";
    }
  }

  mounted() {
    this.editing = this.edit;
    this.editingTodo = clonedeep(this.todo);
    if (!this.todo.id) {
      this.editingTodo.creator = this.storeUser;
      this.editingTodo.assignedUser = this.storeUser;
    }
  }

  delayedSave() {
    setTimeout(() => {
      this.save();
    }, 250);
  }

  save() {
    if (this.todo?.id) {
      this.editingTodo.id = this.todo.id;
      this.editTodo(this.editingTodo);
    } else {
      this.addTodo(this.editingTodo);
    }
    this.editing = false;
    this.$emit("onSave");
  }
  cancel() {
    this.editingTodo = clonedeep(this.todo);
    this.$emit("onCancel");
    this.editing = false;
  }
}
