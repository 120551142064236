import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/performance';

import firebase from 'firebase/app';

let firebaseConfig = {};

if (process.env.NODE_ENV === "development") {
    firebaseConfig = {
        apiKey: "AIzaSyCxuDIBPwYve_eXESf2qO1jjK-5BBsvme8",
        authDomain: "ccfnq-core-dev.firebaseapp.com",
        databaseURL: "https://ccfnq-core-dev.firebaseio.com",
        projectId: "ccfnq-core-dev",
        storageBucket: "ccfnq-core-dev.appspot.com",
        messagingSenderId: "713680162931",
        appId: "1:713680162931:web:18d7461b221b7f5ed11a06",
        measurementId: "G-D4DP8KRJPM"
    };
} else {
    firebaseConfig = {
        apiKey: "AIzaSyAXbVwpkJzIIVIDCvZuN09zOlDT2kzWCkk",
        authDomain: "ccfnq-core.firebaseapp.com",
        databaseURL: "https://ccfnq-core.firebaseio.com",
        projectId: "ccfnq-core",
        storageBucket: "ccfnq-core.appspot.com",
        messagingSenderId: "751432789734",
        appId: "1:751432789734:web:f1f51c8b397f5ec7051527",
        measurementId: "G-B3WSN9M591"
    };
}
// Get a Firestore instance
const configuredFirebase = firebase.initializeApp(firebaseConfig);
export const db = configuredFirebase.firestore();
db.settings({ ignoreUndefinedProperties: true });
export default db;
export const MAX_BATCH_OPPERATIONS = 500;
export const fbIncrementOne = firebase.firestore.FieldValue.increment(1);

configuredFirebase.analytics();
configuredFirebase.performance();

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const {
    Timestamp,
    GeoPoint
} = firebase.firestore;

export {
    Timestamp,
    GeoPoint
};