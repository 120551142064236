import { db, fbIncrementOne } from '@/db';
import Job from '@/model/job';
import { COLLECTION_NAME as CUSTOMER_COLLECTION_NAME } from '@/store/modules/customer';
import { firestoreAction } from 'vuexfire';


export const COLLECTION_NAME = "jobs";
const STOREVARIABLE_NAME = "alljobs";


const state = {
	alljobs: new Array<Job>()
};

const getters = {
	jobs(): Job[] {
		return [...state.alljobs]
	},
	sortedJobs(): Job[] {
		return [...state.alljobs/*.filter((x) => !x.toInvoice)*/
			.sort((a, b) => (a.number > b.number ? 1 : -1))]
	}
};

const actions = {
	bindJobRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collectionGroup(COLLECTION_NAME).withConverter(Job.postConverter))
	}),
	addJob({ }, job: Job) { // eslint-disable-line
		db.collection("counters").doc("job").update({ count: fbIncrementOne }).then(() => {
			if (job.customerID == "") {
				job.customerID = "onetimejob";
			}
			job.number = job.number?.toString().toUpperCase();
			return db.collection(CUSTOMER_COLLECTION_NAME).doc(job.customerID).collection(COLLECTION_NAME).withConverter(Job.postConverter).add(job);
		})
	},
	editJob({ }, job: Job) { // eslint-disable-line
		return db.collection(CUSTOMER_COLLECTION_NAME).doc(job.customerID).collection(COLLECTION_NAME).doc(job.id).withConverter(Job.postConverter).set(job);
	},
	deleteJob({ }, job: Job) { //eslint-disable-line
		return db.collection(CUSTOMER_COLLECTION_NAME).doc(job.customerID).collection(COLLECTION_NAME).doc(job.id).delete();
	}
}

export default {
	state,
	getters,
	actions
}