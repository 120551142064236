import { Timestamp } from "@/db";
import firebase from "firebase";
import User from "./user";
// import { Tag } from "./interfaces"

// This has been resolved by a flag in the firestore config to ignore undefined values
// const removeUndefined = (obj: any): any =>
// 	Object.fromEntries(Object.entries(obj)
// 		.filter(([, v]) => v !== undefined)
// 		.map(([k, v]) => (typeof v === "object" ? [k, removeUndefined(v)] : [k, v]))
// 	);

export default class Todo {
	["id"]: string;

	details: string = "";
	tags: string[] = [];
	dueDate?: firebase.firestore.Timestamp = undefined;
	done: boolean = false;
	project: string = "";
	assignedUser?: User = undefined;
	creator?: User = undefined;
	lastActionUser?: User = undefined;
	lastActionTimestamp?: firebase.firestore.Timestamp = undefined;
	priority: number = 3;
	//~~~~
	// assigned: Assigned = { by: "", to: "" }
	// notes: string = "";
	// job: string = "";
	// jobCachedTitle: string = "";
	// created = Timestamp.now();


	constructor() {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
	}

	valueOf(): string { // try to help the unknown vuetify comparison when comparing objects for a list component
		return this.id;
	}

	static postConverter = {
		toFirestore(todo: Todo): firebase.firestore.DocumentData {
			if (todo.dueDate) {
				if (typeof todo.dueDate === "string") {
					todo.dueDate = Timestamp.fromDate(new Date(todo.dueDate))
					// @ts-ignore
				} else if (typeof todo.dueDate.getUTCMilliseconds === "function") { //This will be a Date()
					// @ts-ignore
					todo.dueDate = Timestamp.fromDate(todo.dueDate)
				}
			}

			return {
				...todo,
				assignedUser: { ...todo.assignedUser },
				creator: { ...todo.creator },
				lastActionUser: { ...todo.lastActionUser }
			};
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot, // eslint-disable-line
			options: firebase.firestore.SnapshotOptions // eslint-disable-line
		): Todo {
			return Object.assign(new Todo(), snapshot.data(options));
		}
	}


}

