import { db, Timestamp } from '@/db';
import Todo from '@/model/todo';
import { firestoreAction } from 'vuexfire';

const COLLECTION_NAME = "todos";
const STOREVARIABLE_NAME = "alltodos";

const state = {
    alltodos: new Array<Todo>()
};

const getters = {
    todos(): Todo[] {
        return [...state.alltodos]
    }
};

const actions = {
    bindTodoRef: firestoreAction(context => {
        return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(Todo.postConverter))
    }),
    addTodo({ getters }: any, todo: Todo) { // eslint-disable-line
        todo.lastActionTimestamp = Timestamp.now();
        todo.creator = getters.getStoreUser
        todo.lastActionUser = getters.getStoreUser
        return db.collection(COLLECTION_NAME).withConverter(Todo.postConverter).add(todo);
    },
    editTodo({ getters }: any, todo: Todo) { // eslint-disable-line
        todo.lastActionTimestamp = Timestamp.now();
        todo.lastActionUser = getters.getStoreUser
        return db.collection(COLLECTION_NAME).doc(todo.id).withConverter(Todo.postConverter).set(todo);
    },
    deleteTodo({ }, id: any) { // eslint-disable-line
        return db.collection(COLLECTION_NAME).doc(id).delete();
    }
}

export default {
    state,
    getters,
    actions
}