import firebase from "firebase/app";

export default class User {
	id: string = "";
	displayName: string = "";
	email: string = "";
	photoURL: string = "";

	constructor(user?: firebase.User) {
		this.id = user?.uid || "";
		this.displayName = user?.displayName || "";
		this.email = user?.email || "";
		this.photoURL = user?.photoURL || "";
	}

	static postConverter = {
		toFirestore(user: User): firebase.firestore.DocumentData {
			return { ...user };
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot, // eslint-disable-line
			options: firebase.firestore.SnapshotOptions // eslint-disable-line
		): User {
			const data = snapshot.data(options)!;
			return Object.assign(new User(), data);
		}
	}
}