import { ContactDetails, Rating } from '../model/interfaces';
import firebase from "firebase";

export default class Customer {
	["id"]: string;


	contact: ContactDetails = { name: "", email: "", number: "", address: "" }
	contacts: ContactDetails[] = [];
	name: string = "";
	rating: number = 0;
	ratings: Rating[] = [];
	jobNumberCode: string = "";


	static postConverter = {
		toFirestore(customer: Customer): firebase.firestore.DocumentData {
			customer.contacts = customer.contacts.map((contact: ContactDetails) => ((obj: any) => {
				for (const k in obj) if (obj[k] === undefined) delete obj[k];
				return obj;
			})(contact))
			return { ...customer };
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot, // eslint-disable-line
			options: firebase.firestore.SnapshotOptions // eslint-disable-line
		): Customer {
			const data = snapshot.data(options)!;
			return Object.assign(new Customer(), data);
		}
	}
}