<template>
  <div class="mt-10">
    <img
      src="/apple-touch-icon.png"
      style="
        height: 100px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      "
    />
    <v-card width="400" class="mx-auto mt-15">
      <v-card-title>
        <h1 class="display-1">Login</h1>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field prepend-icon="mdi-account-circle" label="Username" />
          <v-text-field
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="success">Register</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info">Login</v-btn>
        <v-btn
          color="info"
          :loading="loading"
          :disabled="loading"
          @click="signInWithGoogle"
        >
          <template v-slot:loader>
            <v-progress-circular
              :size="17"
              :width="3"
              indeterminate
              class="mr-2"
            />Loading...
          </template>
          Sign in with Google
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["loading", "signInWithGoogle"],
  //   methods: {
  //     signInWithGoogle() {
  //       this.$parent.signInWithGoogle();
  //     }
  //   },
  data() {
    return {
      showPassword: false,
    };
  },
};
</script>
