import { db } from '@/db';
import Customer from '@/model/customer';
import { firestoreAction } from 'vuexfire';

export const COLLECTION_NAME = "customers";
const STOREVARIABLE_NAME = "allcustomers";

const state = {
	allcustomers: new Array<Customer>()
};

const getters = {
	customers(): Customer[] {
		return [...state.allcustomers]
	}
};

const actions = {
	bindCustomerRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(Customer.postConverter))
	}),
	addCustomer({ }, customer: Customer) { // eslint-disable-line
		return db.collection(COLLECTION_NAME).withConverter(Customer.postConverter).add(customer);
	},
	editCustomer({ }, customer: Customer) { // eslint-disable-line
		return db.collection(COLLECTION_NAME).doc(customer.id).withConverter(Customer.postConverter).set(customer);
	},
	deleteCustomer({ }, customer: Customer) { // eslint-disable-line
		return db.collection(COLLECTION_NAME).doc(customer.id).delete();
	}
}

export default {
	state,
	getters,
	actions
}