


















































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Mutation } from "vuex-class";
import TodoItem from "./TodoItem.vue";
import Todo from "../../model/todo";
import Job from "../../model/job";
import User from "../../model/user";
import _ from "lodash";
import { Prop } from "vue-property-decorator";
import TodoMenu from "./TodoMenu.vue";

interface filter {
  toInvoice: boolean;
  jobID: string;
  tag: string;
  assignedUserID: string;
  assignedUser: User | undefined;
}
@Component({
  components: { TodoItem, "c-todo-menu": TodoMenu },
})
export default class TodoList extends Vue {
  @Prop() fullscreen!: boolean;
  @Getter("todoDrawerState") todoDrawer!: boolean;
  @Getter("filteredJobs") filteredJobs!: Job[];
  @Getter("jobs") jobs!: Job[];
  @Getter("todos") todos!: Todo[];
  @Getter("users") users!: User[];
  @Getter("getStoreUser") storeUser!: User;
  @Mutation("toggleTodoDrawer") toggleTodoDrawer!: () => void;
  newTodo: boolean = false;
  newTodoObject: Todo = new Todo();
  groupByJob: boolean = true;
  hideDone: boolean = true;

  get filteredTodos(): Record<string, Todo[]> {
    let results = _.sortBy(this.todos, (o) => o.dueDate).filter((todo) => {
      let ret = false;
      if (!this.hideDone || !todo.done) {
        ret = true;
        if (this.todoFilter.jobID != "") {
          ret = false;
          if (todo.project === this.todoFilter.jobID) {
            ret = true;
          }
        }
        if (this.todoFilter.assignedUserID != "") {
          ret = false;
          if (
            todo.assignedUser != undefined &&
            todo.assignedUser.id == this.todoFilter.assignedUserID
          ) {
            ret = true;
          }
        } else if (this.todoFilter.assignedUser != undefined) {
          ret = false;
          if (
            todo.assignedUser != undefined &&
            todo.assignedUser.id == this.todoFilter.assignedUser.id
          ) {
            ret = true;
          }
        }
        if (this.todoFilter.tag != "") {
          ret = false;
          if (todo.tags.length > 0) {
            todo.tags.forEach((element: any) => {
              if (element == this.todoFilter.tag) {
                ret = true; //this can get refactored to work better
              }
            });
          }
        }
      }
      return ret;
    });
    if (this.groupByJob) {
      return _.groupBy(results, (x) =>
        Job.TitleFormatter(
          this.jobs.find((j) => j.id == x.project) || new Job()
        )
      );
    }
    return { All: results };
  }

  static defaultFilter(): filter {
    return {
      toInvoice: false,
      jobID: "",
      tag: "",
      assignedUserID: "",
      assignedUser: undefined,
    };
  }
  todoFilter: filter = TodoList.defaultFilter();

  get menuStyles() {
    if (this.todoDrawer && !this.fullscreen) {
      return "height: 100%; overflow-x: hidden; overflow-y: scroll; ";
    } else {
      return "height: 100%; overflow-x: hidden; overflow-y: scroll; margin-left: 15px;";
    }
  }

  filterByJob(job: Job) {
    this.todoFilter = TodoList.defaultFilter();
    this.todoFilter.jobID = job.id;
  }

  filterByUser(user: User) {
    this.todoFilter.assignedUserID = user.id;
  }

  filterByTag(tagname: string) {
    this.todoFilter = TodoList.defaultFilter();
    this.todoFilter.tag = tagname;
  }

  filterInbox() {
    this.todoFilter = TodoList.defaultFilter();
    this.todoFilter.assignedUserID = this.storeUser.id;
  }
  filterViewAll() {
    this.todoFilter = TodoList.defaultFilter();
  }

  addTodo() {
    this.newTodo = true;
    this.newTodoObject = new Todo();
    this.$vuetify.goTo(0);
  }
  onSave() {
    this.newTodo = false;
  }
  onCancel() {
    this.newTodo = false;
  }
}
