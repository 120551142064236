import { db } from '@/db';
import { firestoreAction } from 'vuexfire';
import User from '@/model/user';

const COLLECTION_NAME = "users";
const STOREVARIABLE_NAME = "allusers";

const state = {
	allusers: new Array<User>()
};

const getters = {
	users(): User[] {
		return [...state.allusers]
	}
};

const actions = {
	bindUserRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(User.postConverter))
	}),
	addUser({ }, user: User) { // eslint-disable-line
		return db.collection(COLLECTION_NAME).doc(user.id).withConverter(User.postConverter).set(user);
	},
	editUser({ }, user: User) { // eslint-disable-line
		return db.collection(COLLECTION_NAME).doc(user.id).withConverter(User.postConverter).set(user);
	},
	deleteUser({ }, id: any) { // eslint-disable-line
		return db.collection(COLLECTION_NAME).doc(id).delete();
	}
}

export default {
	state,
	getters,
	actions
}