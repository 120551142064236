











































import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import Login from "@/components/Login.vue";
import { Component } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import User from "@/model/user";

@Component({
  components: { Login },
})
export default class App extends Vue {
  @Action("bindTodoRef") bindTodoRef!: () => any;
  @Action("bindUserRef") bindUserRef!: () => any;
  @Action("bindJobRef") bindJobRef!: () => any;

  @Getter("users") users!: User[];
  @Getter("getStoreUser") storeUser!: User;
  @Action("addStoreUser") addStoreUser!: (user: User) => any;
  @Action("removeStoreUser") removeStoreUser!: () => void;
  @Action("addUser") addUser!: (user: User) => any;
  @Mutation("toggleTodoDrawer") toggleTodoDrawer!: () => void;

  loading: boolean = true;

  signInWithGoogle() {
    this.loading = true;
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithRedirect(provider)
      .catch((error) => console.error(error));
  }

  signOut() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.removeStoreUser();
        //TODO: unbind all references @low
      })
      .catch((error) => console.log(error));
  }
  beforeCreate() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.bindTodoRef();
        this.bindUserRef();
        this.bindJobRef();

        let currentUser = this.users.find((x) => (x.id = user.uid));
        if (!currentUser) {
          currentUser = new User(user);
          this.addUser(currentUser);
        }
        this.addStoreUser(currentUser);
      }
      this.loading = false;
    });
  }
}
