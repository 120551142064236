




import { Component, Vue } from "vue-property-decorator";
import TodoList from "../components/Todo/TodoList.vue";

@Component({
  components: { TodoList },
})
export default class Home extends Vue {}
