import firebase from "firebase";

export default class Job {
	["id"]: string;

	address: string = "";
	customerName: string = "";
	customerID: string = "";
	description: string = "";
	name: string = "";
	number: string = "";
	status: string = "";
	toInvoice: boolean = false;
	invoiced: boolean = false;
	propertyservices: boolean = false;

	constructor(status: string = "") {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
		this.status = status;
	}

	static postConverter = {
		toFirestore(job: Job): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here
			return { ...job };
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot, // eslint-disable-line
			options: firebase.firestore.SnapshotOptions // eslint-disable-line
		): Job {
			const data = snapshot.data(options)!;
			return Object.assign(new Job(), data);
		}
	}

	static TitleFormatter(job: Job): string {
		if (job.number && job.name) {
			return `${job.number} - ${job.name}`;
		} else {
			return "";
		}
	}
}