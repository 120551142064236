




































































import Vue from "vue";

import { Component } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
import { Prop } from "vue-property-decorator";
import Todo from "../../model/todo";
import Job from "../../model/job";

@Component
export default class TodoMenu extends Vue {
  @Getter("todoDrawerState") todoDrawer!: boolean;
  @Mutation("toggleTodoDrawer") toggleTodoDrawer!: () => void;
  @Prop() fullscreen!: boolean;
  @Getter("getTodoTags") todoTags!: [];
  @Getter("todos") todos!: Todo[];
  @Getter("jobs") jobs!: Job[];

  unique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  get filteredJobsfromTodos() {
    return this.todos
      .filter((todo) => !todo.done && todo.project)
      .map((x) => {
        return this.jobs.find((j: Job) => j.id == x.project);
      })
      .filter(this.unique)
      .sort((a, b) =>
        a != undefined && b != undefined && a.number > b.number ? 1 : -1
      );
  }

  filterInbox() {
    this.$emit("filterInbox");
  }
  filterViewAll() {
    this.$emit("filterViewAll");
  }

  filterByTag(tagname: string) {
    this.$emit("filterByTag", tagname);
  }
  filterByJob(job: Job) {
    this.$emit("filterByJob", job);
  }
}
