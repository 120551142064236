import 'firebase/firestore';

import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations } from 'vuexfire';
import job from './modules/job';
import todo from './modules/todo';
import user from './modules/user';
import User from '@/model/user';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    todoDrawer: true,
    storeUser: undefined,
    todoTags: [
      { name: "Rainy Day", color: "lime" },
      { name: "Fill In Job", color: "deep-orange" },
      { name: "Site Visit", color: "amber" },
      { name: "Roof Leak", color: "blue-grey" },
      { name: "Fix-up", color: "pink" }
    ]
  },
  mutations: {
    ...vuexfireMutations,
    toggleTodoDrawer(state: any) {
      state.todoDrawer = !state.todoDrawer;
    }
  },
  actions: {
    addStoreUser({ state }, user: User) {
      state.storeUser = user;
    },
    removeStoreUser({ state }) {
      state.storeUser = undefined;
    }
  },
  getters: {
    todoDrawerState: state => {
      return state.todoDrawer
    },
    getStoreUser: state => {
      return state.storeUser;
    },
    getTodoTags: state => {
      return state.todoTags;
    }
  },
  modules: {
    todo,
    job,
    user
  }
});
